// extracted by mini-css-extract-plugin
export var blogPosts = "BlogPostsIndex__blogPosts__DV7em";
export var blogPostsContainer = "BlogPostsIndex__blogPostsContainer__Nt1yZ";
export var blogPostsContent = "BlogPostsIndex__blogPostsContent__kkKlb";
export var blogPostsLayout = "BlogPostsIndex__blogPostsLayout__I1wgW";
export var categoriesList = "BlogPostsIndex__categoriesList__DnfVZ";
export var categoryImagePlaceholder = "BlogPostsIndex__categoryImagePlaceholder__VqQOe";
export var categoryList = "BlogPostsIndex__categoryList__hR5HR";
export var categoryTitle = "BlogPostsIndex__categoryTitle__KUISm";
export var column = "BlogPostsIndex__column__hEurz";
export var flex = "BlogPostsIndex__flex__rqINt";
export var flexColumn = "BlogPostsIndex__flexColumn__bzgSt";
export var gap1 = "BlogPostsIndex__gap1__FfSK2";
export var gap2 = "BlogPostsIndex__gap2__ZsrsA";
export var gap3 = "BlogPostsIndex__gap3__Oi8IQ";
export var gap4 = "BlogPostsIndex__gap4__EW09y";
export var gap5 = "BlogPostsIndex__gap5__xlK5X";
export var morePosts = "BlogPostsIndex__morePosts__Fc05Q";
export var postDate = "BlogPostsIndex__postDate__s3XgV";
export var postTitle = "BlogPostsIndex__postTitle__T0SM7";
export var row = "BlogPostsIndex__row__HEKKi";
export var sidebar = "BlogPostsIndex__sidebar__vX5Av";
export var title = "BlogPostsIndex__title__bFF2T";
export var titleContainer = "BlogPostsIndex__titleContainer__R9KRF";